import React from 'react';
import PropTypes from 'prop-types';
import { ContextProviderComponent } from './context';

const Layout = ({ children }) => {
  return <ContextProviderComponent>{children}</ContextProviderComponent>;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
