// extracted by mini-css-extract-plugin
export var first = "header-module--first--24760";
export var last = "header-module--last--d9188";
export var menu = "header-module--menu--15238";
export var menu__button = "header-module--menu__button--23596";
export var menu__close = "header-module--menu__close--e2034";
export var menu__item = "header-module--menu__item--cab75";
export var menu__item__spacer = "header-module--menu__item__spacer--2cd4c";
export var menu__open = "header-module--menu__open--daceb";
export var menu__wrapper = "header-module--menu__wrapper--f9cbc";
export var open = "header-module--open--641d9";