import React from 'react';

import Layout from './src/components/layout/layout';
import Header from './src/components/layout/header';
import './src/components/layout/layout.scss';
import Transition from './src/components/transition/transition';
import { AppProvider } from './src/components/layout/contextTransition';

export const wrapPageElement = ({ element, props }) => {
  //   console.log('props', props);

  return (
    <AppProvider>
      <Layout {...props}>
        <Header siteTitle="ARMON SEMADENI<br>ARCHITEKTEN" />

        <Transition>{element}</Transition>
      </Layout>
    </AppProvider>
  );
};
