import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';

import * as style from './header.module.scss';

import ContextConsumer from './context';
import { useAppContext } from './contextTransition';

const Header = () => {
  const content = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      wpcontent {
        categories {
          nodes {
            name
            slug
          }
        }

        pageBy(uri: "buero") {
          id
          buerofields {
            phone
            email
            address
            city
          }
        }
      }
    }
  `);

  // console.log('header style', style);

  const { setPreviousIsProject } = useAppContext();

  return (
    <header>
      <nav>
        <div>
          <h1>
            <Link
              to="/"
              onMouseEnter={() => {
                // console.log('unsetting previous project', setPreviousIsProject);
                // TODO: Quick fix to prevent Project to project transition!
                setPreviousIsProject(false);
              }}
              // onMouseLeave={() => setPreviousIsProject(false)}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: 'Armon Semadeni<br>Architekten'.toUpperCase(),
                }}
              />
            </Link>
          </h1>
        </div>
        <ContextConsumer>
          {({ data, set }) => (
            <div
              className={style.menu__open}
              onClick={() => {
                // console.log('Click Menu Button', !data.menuOpen);
                // console.log('data', data);
                set({ menuOpen: !data.menuOpen });
              }}
              onKeyDown={() => set({ menuOpen: !data.menuOpen })}
              role="button"
              tabIndex={0}
              aria-label="Hamburger"
            >
              <div
                className={`${style.menu__button} ${
                  data.menuOpen ? style.open : ''
                }`}
              >
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
          )}
        </ContextConsumer>

        <ContextConsumer>
          {({ data, set }) => (
            <Menu
              content={content}
              menuOpen={data.menuOpen}
              handleNavigation={() => set({ menuOpen: !data.menuOpen })}
            />
          )}
        </ContextConsumer>
      </nav>
    </header>
  );
};

const Menu = ({ content, menuOpen, handleNavigation }) => {
  const { setPreviousIsProject } = useAppContext();

  // console.log('Menu is open', menuOpen);s

  return (
    <div
      className={`${style.menu} ${menuOpen ? style.open : ''}`}
      onClick={handleNavigation}
      onKeyDown={handleNavigation}
      role="button"
      tabIndex={0}
    >
      <div className={style.menu__close}></div>
      <div className={style.menu__wrapper}>
        {content.wpcontent.categories.nodes.map((category, i) => {
          if (category.slug !== 'uncategorized') {
            return (
              <div
                className={`${style.menu__item} ${i === 0 ? style.first : ''}`}
                key={i}
              >
                <Link
                  to={`/${category.slug}/`}
                  state={{
                    filter: category.slug,
                  }}
                  onMouseEnter={() => {
                    // console.log('unsetting previous project', setPreviousIsProject);
                    // TODO: Quick fix to prevent Project to project transition!
                    setPreviousIsProject(false);
                  }}
                >
                  {category.name}
                </Link>
              </div>
            );
          } else {
            return false;
          }
        })}
        <div className={`${style.menu__item}`}>
          <Link
            to={`/studien/`}
            onMouseEnter={() => {
              // console.log('unsetting previous project', setPreviousIsProject);
              // TODO: Quick fix to prevent Project to project transition!
              setPreviousIsProject(false);
            }}
          >
            Studien
          </Link>
        </div>
        <div className={style.menu__item__spacer}></div>
        <div className={style.menu__item}>
          <Link
            to="/buero"
            onMouseEnter={() => {
              // console.log('unsetting previous project', setPreviousIsProject);
              // TODO: Quick fix to prevent Project to project transition!
              setPreviousIsProject(false);
            }}
          >
            Büro
          </Link>
        </div>
        <div className={`${style.menu__item} ${style.last}`}>
          <a href={`tel:+${content.wpcontent.pageBy.buerofields.phone}`}>
            {content.wpcontent.pageBy.buerofields.phone}
          </a>
          <br />
          <a href={`mailto:${content.wpcontent.pageBy.buerofields.email}`}>
            {content.wpcontent.pageBy.buerofields.email}
          </a>
          <br />
          <address>
            {content.wpcontent.pageBy.buerofields.address}
            <br />
            {content.wpcontent.pageBy.buerofields.city}
          </address>
        </div>
      </div>
    </div>
  );
};

export default Header;
