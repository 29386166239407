import React, { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from '@reach/router'; // Helps ensure unique keys per route in Gatsby

// Define the shape of the context state

// Create context with default values
const AppContext = createContext(null);

// Provider component
export const AppProvider = ({ children }) => {
  const [imageId, setImageId] = useState(null);
  const [category, setCategory] = useState(null);
  const [previousIsProject, setPreviousIsProject] = useState(false);
  //   const [nextPath, setNextPath] = useState(null); // Track next route

  //   // Handle navigation and update the nextPath BEFORE transition starts
  //   const handleNavigate = (next) => {
  //     setNextPath(next);
  //     navigate(next);
  //   };

  // console.log('cartegory', category);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.startsWith('/projekte/')) {
      setPreviousIsProject(true);
    } else {
      setPreviousIsProject(false);
    }
    setTimeout(() => {
      setImageId(null);
    }, 300);

    const pathname = location.pathname.slice(1, location.pathname.length - 1);
    // console.log('pathname', pathname);
    // const cleanedPath
    // Handle Categories:
    if (
      pathname === 'offentliche-bauten' ||
      pathname === 'wohnbauten' ||
      pathname === 'geschaftsbauten' ||
      pathname === 'umbauten'
    ) {
      setCategory(pathname);
    }

    // return () => {
    //   setImageId(null);
    // };
  }, [location.pathname]);

  return (
    <AppContext.Provider
      value={{
        imageId,
        setImageId,
        category,
        setCategory,
        previousIsProject,
        setPreviousIsProject,
        // nextPath,
        // handleNavigate,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Custom hook for using the context
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error('useAppContext must be used within an AppProvider');
  }
  return context;
};
