import React, { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useLocation } from '@reach/router'; // Helps ensure unique keys per route in Gatsby
import { useAppContext } from '../layout/contextTransition';

const TRANSITION_LENGTH = 0.6;
// // const TRANSITION_LENGTH = 0.6

const exitTransition = {
  delay: 0,
  // delay: TRANSITION_LENGTH, // Take 1.5 seconds to leave
  length: TRANSITION_LENGTH, // Take 1.5 seconds to leave
  // trigger: ({ exit, node}) => {
  // console.log('we are exciting ',exit, node)
  // }
};

const entryTransition = {
  // delay: 0, // Wait 1.5 seconds before entering
  // length: TRANSITION_LENGTH
  // trigger: ({ exit, node}) => {
  //   console.log('we are entering ',exit, node)
  // }
};

export { exitTransition, entryTransition };

const transitionX = {
  ease: 'easeOut',
  duration: 0.6,
  // duration: 1.6,
};

const transitionY = {
  ease: 'easeOut',
  duration: 0.6,
  // duration: 1.6,
};

// Handle all situations
// ––––––––––––––––––––––
// Index or Category > Project x: +100%
// Project > Index or Category x: -100%
// –––
// Project > Project – y: -100%
// –––

const animationVariants = {
  // initial: { x: '-100%', y: '0%', opacity: 1, transition: transitionX },
  // animate: { x: '0%', y: '0%', opacity: 1, transition: transitionX },
  // exit: { x: '100%', y: '0%', opacity: 1, transition: transitionX },
  initial: { x: '100%', y: '0%', opacity: 1, transition: transitionX },
  animate: { x: '0%', y: '0%', opacity: 1, transition: transitionX },
  exit: { x: '-100%', y: '0%', opacity: 1, transition: transitionX },
};

const animationVariantsNext = {
  initial: { x: '0%', y: '100%', opacity: 1, transition: transitionY },
  animate: { x: '0%', y: '0%', opacity: 1, transition: transitionY },
  exit: { x: '0%', y: '-100%', opacity: 1, transition: transitionY },
};

const Transition = ({ children, previousPost }) => {
  const location = useLocation(); // Ensures unique key for each route change
  // key={location.key}
  const { previousIsProject } = useAppContext();

  const isVerticalTranision =
    previousIsProject && !!location.pathname.match('/projekte/');
  // const isVerticalTranision = false;

  const variants = isVerticalTranision
    ? animationVariantsNext
    : animationVariants;

  // Prevent initial animation!!!
  const [isFirst, setIsFirst] = useState(true);

  useEffect(() => {
    setIsFirst(false);
  }, []);

  // const [delayedPath, setDelayedPath] = useState(location.pathname);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setDelayedPath(location.pathname);
  //   }, 100); // Small delay before animating

  //   return () => clearTimeout(timer);
  // }, [location.pathname]);

  return (
    <AnimatePresence
      mode="sync"
      onExitComplete={() => {
        // console.log('Exit animation complete')
      }}
    >
      <motion.div
        key={location.pathname}
        // key={delayedPath}
        // ––––––
        variants={variants}
        initial={isFirst ? false : 'initial'}
        animate="animate"
        exit="exit"
        style={{
          position: 'absolute',
          width: '100%',
          height: `100vh`,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          overflow: 'hidden',
        }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
};

export default Transition;
