// Context.js
import React from 'react';
// import React, { useState } from "react"

const defaultContextValue = {
  data: {
    // set your initial data shape here
    menuOpen: false,
  },
  set: () => {},
};

const { Provider, Consumer } = React.createContext(defaultContextValue);

class ContextProviderComponent extends React.PureComponent {
  // class ContextProviderComponent extends React.Component {
  constructor(props) {
    super(props);

    this.setData = this.setData.bind(this);

    this.state = {
      ...defaultContextValue,
      set: this.setData,
    };
  }

  setData(newData) {
    // console.log('newData', newData);
    this.setState((state) => ({
      data: {
        ...state.data,
        ...newData,
      },
    }));
  }

  render() {
    return <Provider value={this.state}>{this.props.children}</Provider>;
  }
}

export { Consumer as default, ContextProviderComponent };
